import React from 'react';
import myVideo from './asset/img/Animal_Beak.mp4';

const Test = () => {
    return (
        <div>
            <div className="video-background">
                <video className='video'
                    src={myVideo} type="video/mp4"
                    autoPlay loop muted style={{
                        width: "100%", height: "100%",
                        position: 'absolute',
                        width: '500px',
                        height: '100px',
                        objectFit: 'cover',
                    }} />
                {/* <source src={myVideo} type="video/mp4" /> */}
                <div>hi ram</div>
                <div className="content">
                </div>
            </div>
        </div>
    );
}

export default Test;
