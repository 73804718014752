import CryptoJS from 'crypto-js';

const decryptData = (encryptedText, secretKey) => {
    try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedText;
    } catch (error) {
        console.error('Decryption error:', error);
        return null;
    }
};

export default decryptData;