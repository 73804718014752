import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import encryptData from './encryptData';
import decryptData from './decryptData';
const Login = () => {
    const [user, setuser] = useState(null)
    const [pass, setpass] = useState(null)
    const history = useNavigate();

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [originalText, setOriginalText] = useState('');
    const [encryptedText, setEncryptedText] = useState('');
    const [decryptedText, setDecryptedText] = useState('');

    const secretKey = 'thesecretkeyShouldBeACombinationOf@specialChars'; // Replace with a strong secret key

    // const handleEncrypt = () => {
    //     const encrypted = encryptData(originalText, secretKey);
    //     if (encrypted) {
    //         setEncryptedText(encrypted);
    //     }
    // };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your authentication logic here
        console.log('Login attempt:', formData);

        const encrypted = encryptData(formData.username, secretKey);
        if (encrypted) {
            setEncryptedText(encrypted);
        }

        const decrypted = decryptData(encrypted, secretKey);
        if (decrypted) {
            setDecryptedText(decrypted);
        }


        console.log(encrypted, 'encryptedencrypted')
        console.log(decrypted, 'encryptedencrypted')
        // Reset form after submission
        setFormData({ username: '', password: '' });

        if (formData.username == 'george' && formData.password == 'medvatic') {
            console.log(true)
        }
        // history(`/ShowMenu`)



        fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: formData.username, password: formData.password })
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                // setBlogs(res.data.blogData)
                // setrefetch(!refetch)
                if (res.code == 200) {
                    localStorage.setItem('isLoggedIn', true);
                    localStorage.setItem('timestamp', new Date());
                    history(`/ShowMenu`)

                } else {
                    alert('username and password does not match!')

                }
            }).catch(e => {
                console.log(e)

                alert('Something went wrong,please try again')
            });


    };

    // Function to handle input changes
    const handleChange = (e) => {
        console.log(e)
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log(formData)
    };

    // type = "text"
    // name = "username"
    // value = { formData.username }
    // onChange = { handleChange }

    return (
        <Container>
            <LoginForm>
                <Title>Login</Title>
                <Input type="text" onChange={handleChange} name='username' value={formData.username} placeholder="Username" />
                <Input type="password" onChange={handleChange} name='password' value={formData.password} placeholder="Password" />
                <Button onClick={handleSubmit}>Login</Button>
            </LoginForm>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8ea; /* Light Green Background */
`;

const LoginForm = styled.form`
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  color: #4caf50; /* Green Text Color */
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50; /* Green Button Color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049; /* Darker Green on Hover */
  }
`;

export default Login;
