import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FEHHeader from './FEHHeader';

const ShowMenu = () => {
  const [Blogs, setBlogs] = useState([])
  const [weeklypost, setweeklypost] = useState([])
  const [audiencePost, setaudiencePost] = useState([])
  const history = useNavigate();
  const initialLoggedInState = localStorage.getItem('isLoggedIn') ?? false;
  const initialLoggedInTime = localStorage.getItem('timestamp') ?? false;
  useEffect(() => {
    console.log(initialLoggedInTime == 'false', initialLoggedInTime == false, initialLoggedInTime)
    if (initialLoggedInTime == 'false' || initialLoggedInTime == false) {
      history(`/`)
      return
    }

    else {
      if ((new Date() / 1000 / 60) - (new Date(initialLoggedInTime) / 1000 / 60) > 120) {
        history(`/`)
        return
      }
    }
    console.log('blogs access')
    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/blogs').then(async res => {
      let k = await res.json()
      console.log('this is res', k)
      setBlogs(k.data.blogs)
    }).catch(error => {
      console.log(error)
    })

    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchWeeklyPost', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }).then(res => res.json())
      .then(res => {
        console.log(res.data.weeklyPost)
        setweeklypost(res.data.weeklyPost.posts)
        // setBlogs(res.data.blogData)
        // setrefetch(!refetch)
      }).catch(e => {
        console.log(e)
        // alert('Something went wrong,please try again')
      });

    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchAudiencePost', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }).then(res => res.json())
      .then(res => {
        console.log(res.data)
        setaudiencePost(res.data.audiencePosts.audiencePost)
        // setBlogs(res.data.blogData)
        // setrefetch(!refetch)
      }).catch(e => {
        console.log(e)
        // alert('Something went wrong,please try again')
      });
  }, []);


  return (
    <NavigationContainer>
      <div style={{ marginTop: '90px' }}>

        <FEHHeader />
      </div>
      {/* <NavigationLink to="/">Home</NavigationLink> */}
      <NavigationLink >Edit Blogs</NavigationLink>
      <div style={{}}>

        {Blogs && <div>
          {Blogs.map(x => <div style={{ padding: '30px', margin: '10px', border: 'solid 1px #000', borderRadius: '10px', cursor: "pointer" }} onClick={() => history(`/EditBlogDetails/${x.blogId}`)}>
            {x.heading}</div>)}
        </div>}

      </div>
      <NavigationLink >Edit Weekly Posts</NavigationLink>
      <div style={{ margin: '10px' }}>

        {weeklypost && <div>
          {weeklypost.map((x, index) => <div style={{ padding: '30px', margin: '10px', border: 'solid 1px #000', borderRadius: '10px', cursor: "pointer" }} onClick={() => history(`/EditQuotes/${index}`)}>
            {x.title}</div>)}
        </div>}

      </div>

      <NavigationLink>Edit Audience Posts</NavigationLink>
      <div style={{ margin: '10px' }}>

        {audiencePost && <div>
          {audiencePost.map((x, index) => <div style={{ padding: '30px', margin: '10px', border: 'solid 1px #000', borderRadius: '10px', cursor: "pointer" }} onClick={() => history(`/EditAudiencePostDetails/${index}`)}>
            by<div style={{
              padding: '5px', fontWeight: '800',
            }}>{x.name}</div>
            {x.subtext}</div>)}
        </div>}



      </div>
      <NavigationLink to={`/EditHomePage`}>Edit Home Page</NavigationLink>
      <div style={{ margin: '10px' }}>
        {/* 
        {audiencePost && <div>
          {audiencePost.map(x => <div style={{ padding: '30px', margin: '10px', border: 'solid 1px #000', borderRadius: '10px', cursor: "pointer" }} onClick={() => history(`/EditAudiencePostDetails/${x.blogId}`)}>
            by<div style={{
              padding: '5px', fontWeight: '800',
            }}>{x.name}</div>
            {x.subtext}</div>)}
        </div>} */}

        {/* <div style={{ padding: '30px', margin: '10px', border: 'solid 1px #000', borderRadius: '10px', cursor: "pointer" }} onClick={() => history(`/EditHomePage`)}>
          by<div style={{
            padding: '5px', fontWeight: '800',
          }}>{'x.name'}</div>
          {'x.subtext'}</div> */}
      </div>
      <NavigationLink to={`/EditAboutPage`}>Edit About page </NavigationLink>
      <div style={{ margin: '10px' }}>

        {/* {audiencePost && <div>
          {audiencePost.map(x => <div style={{ padding: '30px', margin: '10px', border: 'solid 1px #000', borderRadius: '10px', cursor: "pointer" }} onClick={() => history(`/EditAudiencePostDetails/${x.blogId}`)}>
            by<div style={{
              padding: '5px', fontWeight: '800',
            }}>{x.name}</div>
            {x.subtext}</div>)}
        </div>} */}



      </div>
    </NavigationContainer>
  );
}


const NavigationContainer = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: space-around;
  background-color: #4caf50; /* Green Background Color */
  padding: 10px;
`;

const NavigationLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049; /* Darker Green on Hover */
  }
`;
export default ShowMenu;
