import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";
import FEHHeader from "./FEHHeader";
import { FaCheckCircle } from "react-icons/fa";
import sign from './asset/img/sign.png'
import dots from './asset/img/dots.png'
import george1 from './asset/img/about.png'
import worldpeace from './asset/img/worldsaint.png'
import world from './asset/img/world.png'
import { IoMdCheckboxOutline } from "react-icons/io";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import myVideo from './asset/img/Animal_Beak.mp4';

function EditAboutPage(props) {
  const [content, setContent] = useState(null)
  useEffect(() => {
    // fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchAboutPage').
    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchAboutPage', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }).then(res => res.json())
      .then(res => {
        console.log(res.data)
        // setweeklypost(res.data.weeklyPost.posts)
        setContent(res.data.aboutPage)
        // setBlogs(res.data.blogData)
        // setrefetch(!refetch)
      }).catch(e => {
        console.log(e)
        // alert('Something went wrong,please try again')
      });

  }, []);
  return (
    <div style={{ width: '100%', maxWidth: '100%' }}>
      <Div>
        <FEHHeader />
        <Div15>
          <Div16>
            <Div17>About</Div17>

          </Div16>
        </Div15>
        <Div21>
          <div className="videos-background">
            <video className='videoo'
              src={myVideo} type="video/mp4"
              autoPlay loop muted style={{
                position: 'absolute',
                width: '100vw',
                height: '65vh',
                objectFit: 'cover',
                // opacity: '.1'
              }} />
            <div></div>
            <div>

            </div>
          </div>
          <Div22>
            <Div23>About Me</Div23>
            <div style={{
              color: "rgba(255,122,0,1)",
              fontSize: '15px',
              fontWeight: '800',
              objectFit: 'contain',
              objectPosition: 'center',
              overflow: 'hidden',
              alignSelf: 'center',
            }}>. . .</div>
          </Div22>

          <Div24>


            <Div25>
              <Column>
                <Div46>
                  <Div47>Childhood</Div47>
                  <div style={{
                    color: "rgba(255,122,0,1)",
                    fontSize: '15px',
                    fontWeight: '800',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    overflow: 'hidden',
                    alignSelf: 'center',
                  }}>. . .</div>
                </Div46>
                <Div26>
                  <Div28 />


                  {content && <Div520 value={content.childhood} onChange={(e) => {
                    console.log(content)
                    let newArr = { ...content };
                    newArr.childhood = e.target.value;
                    setContent(w => newArr)

                  }} />}

                  <Div41 />


                </Div26>
              </Column>
              <Column2>
                <Div48>
                  <Img11
                    loading="lazy"
                    src={dots}
                  />
                  <Img12
                    loading="lazy"
                    srcSet={george1}
                  />
                  <Img13
                    loading="lazy"
                    src={dots}
                  />
                </Div48>
              </Column2>
            </Div25>


          </Div24>

          <Div46>
            <Div47>Learning</Div47>
            <div style={{
              color: "rgba(255,122,0,1)",
              fontSize: '15px',
              fontWeight: '800',
              objectFit: 'contain',
              objectPosition: 'center',
              overflow: 'hidden',
              alignSelf: 'center',
            }}>. . .</div>
          </Div46>
          {/* <Div49>Empowering Peace, Igniting Creativity</Div49> */}
          <div style={{
            color: "rgba(255,122,0,1)",
            fontSize: '21px',
            fontWeight: '800',
            objectFit: 'contain',
            objectPosition: 'center',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>__________ . __________</div>
          <Div50>
            <Div51>
              <Column3>
                <Div52>
                  {/* {content && <Div53>
                    {content.learning}
                  </Div53>
                  } */}
                  {content && <Div5300 value={content.learning} onChange={(e) => {
                    console.log(content)
                    let newArr = { ...content };
                    newArr.learning = e.target.value;
                    setContent(w => newArr)

                  }} />}

                </Div52>
              </Column3>
            </Div51>
          </Div50>


          <Div46>
            <Div47>Pursuing</Div47>
            <div style={{
              color: "rgba(255,122,0,1)",
              fontSize: '15px',
              fontWeight: '800',
              objectFit: 'contain',
              objectPosition: 'center',
              overflow: 'hidden',
              alignSelf: 'center',
            }}>. . .</div>
          </Div46>
          {/* <Div49>Empowering Peace, Igniting Creativity</Div49> */}
          <div style={{
            color: "rgba(255,122,0,1)",
            fontSize: '21px',
            fontWeight: '800',
            objectFit: 'contain',
            objectPosition: 'center',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>__________ . __________</div>
          <Div50>
            <Div51>

              <Column2>
                <Div48>
                  <Img11
                    loading="lazy"
                    src={dots}
                  />
                  <Img12
                    loading="lazy"
                    srcSet={worldpeace}
                  />
                  <Img13
                    loading="lazy"
                    src={dots}
                  />
                </Div48>
              </Column2>
              <Column3>
                <Div52>
                  {content && <Div53>
                    {/* {content.pursuing} */}
                    {<Div5200 value={content.pursuing} onChange={(e) => {
                      console.log(content)
                      let newArr = { ...content };
                      newArr.pursuing = e.target.value;
                      setContent(w => newArr)

                    }} />}
                    <br />
                    <Div30>
                      <Div31>
                        <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                        <Div320 value={content.pursuingNames[0]}
                          onChange={(e) => {
                            let newArr = { ...content };
                            newArr.pursuingNames[0] = e.target.value;
                            setContent(w => newArr)

                          }}
                        />
                      </Div31>
                      <Div33>
                        <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                        <Div340 value={content.pursuingNames[1]}
                          onChange={(e) => {
                            let newArr = { ...content };
                            newArr.pursuingNames[1] = e.target.value;
                            setContent(w => newArr)

                          }} />
                      </Div33>
                    </Div30>
                    <Div30>
                      <Div31>
                        <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                        {/* <Div32>{content.pursuingNames[2]}</Div32> */}
                        <Div320 value={content.pursuingNames[2]}
                          onChange={(e) => {
                            let newArr = { ...content };
                            newArr.pursuingNames[2] = e.target.value;
                            setContent(w => newArr)

                          }} />
                      </Div31>
                      <Div33>
                        <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                        {/* <Div34>{content.pursuingNames[3]}</Div34> */}
                        <Div340 value={content.pursuingNames[3]}
                          onChange={(e) => {
                            let newArr = { ...content };
                            newArr.pursuingNames[3] = e.target.value;
                            setContent(w => newArr)

                          }} />
                      </Div33>
                    </Div30>
                    <Div30>
                      <Div31>
                        <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                        {/* <Div32>{content.pursuingNames[4]}</Div32> */}
                        <Div320 value={content.pursuingNames[4]}
                          onChange={(e) => {
                            let newArr = { ...content };
                            newArr.pursuingNames[4] = e.target.value;
                            setContent(w => newArr)

                          }} />
                      </Div31>
                      <Div33>
                        <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                        {/* <Div34>{content.pursuingNames[5]}</Div34> */}
                        <Div340 value={content.pursuingNames[5]}
                          onChange={(e) => {
                            let newArr = { ...content };
                            newArr.pursuingNames[5] = e.target.value;
                            setContent(w => newArr)

                          }} />
                      </Div33>
                    </Div30>

                    {/* {content.pursuingSecond} */}
                    {<Div5200 value={content.pursuingSecond} onChange={(e) => {
                      console.log(content)
                      let newArr = { ...content };
                      newArr.pursuingSecond = e.target.value;
                      setContent(w => newArr)

                    }} />}


                  </Div53>}

                </Div52>
              </Column3>

            </Div51>
          </Div50>


          <Div46>
            <Div47>Realizing</Div47>
            <div style={{
              color: "rgba(255,122,0,1)",
              fontSize: '15px',
              fontWeight: '800',
              objectFit: 'contain',
              objectPosition: 'center',
              overflow: 'hidden',
              alignSelf: 'center',
            }}>. . .</div>
          </Div46>
          {/* <Div49>Empowering Peace, Igniting Creativity</Div49> */}
          <div style={{
            color: "rgba(255,122,0,1)",
            fontSize: '21px',
            fontWeight: '800',
            objectFit: 'contain',
            objectPosition: 'center',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>__________ . __________</div>
          <Div50>
            <Div51>
              <Column3>
                <Div52>
                  {/* {content && <Div53>
                    {content.Realizing}
                  </Div53>} */}

                  {content && <Div5300 value={content.Realizing} onChange={(e) => {
                    console.log(content)
                    let newArr = { ...content };
                    newArr.Realizing = e.target.value;
                    setContent(w => newArr)

                  }} />}

                </Div52>
              </Column3>
            </Div51>
          </Div50>

          <Div46>
            <Div47>Creating</Div47>
            <div style={{
              color: "rgba(255,122,0,1)",
              fontSize: '15px',
              fontWeight: '800',
              objectFit: 'contain',
              objectPosition: 'center',
              overflow: 'hidden',
              alignSelf: 'center',
            }}>. . .</div>
          </Div46>
          {/* <Div49>Empowering Peace, Igniting Creativity</Div49> */}
          <div style={{
            color: "rgba(255,122,0,1)",
            fontSize: '21px',
            fontWeight: '800',
            objectFit: 'contain',
            objectPosition: 'center',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>__________ . __________</div>
          <Div50>
            <Div51>
              <Column3>
                <Div52>
                  {content && <Div530 value={content.Creating} onChange={(e) => {
                    console.log(content)
                    let newArr = { ...content };
                    newArr.Creating = e.target.value;
                    setContent(w => newArr)

                  }} />}
                  {/* </Div53>} */}

                </Div52>
              </Column3>

              <Column2>
                <Div48>
                  <Img11
                    loading="lazy"
                    src={dots}
                  />
                  <Img12
                    loading="lazy"
                    srcSet={world}
                  />
                  <Img13
                    loading="lazy"
                    src={dots}
                  />
                </Div48>
              </Column2>
            </Div51>
          </Div50>
          <Div560 onClick={() => {

            fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/editAboutPage', {
              method: 'POST',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ content: content })
            }).then(res => res.json())
              .then(res => {
                console.log(res)
                // setaudiencePost(res.data.audiencePosts.audiencePost)
                // setBlogs(res.data.blogData)
                if (res.code == 200) alert('Edit successfull')
                // setrefetch(!refetch)
              }).catch(e => {
                console.log(e)
                alert('Something went wrong,please try again')
              });

          }}>Save changes</Div560>
        </Div21>

        <Footer />
      </Div>
    </div>
  );
}

const Div = styled.div`
        background-color: #fff;
        display: flex;
        flex-direction: column;
        `;

const Div15 = styled.div`
        background-color: #183862;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px;
        margin:10px;
        @media (max-width: 991px) {
          max-width: 100%;
        padding: 40px 20px;
  }
        `;

const Div16 = styled.div`
        display: flex;
        max-width: 100%;
        flex-direction: column;
        margin: 44px 0 64px;
        @media (max-width: 991px) {
          margin: 40px 0;
  }
        `;

const Div17 = styled.div`
        color: #fff;
        text-align: center;
        letter-spacing: 0.72px;
        white-space: nowrap;
        font: 500 36px/150% Barlow, sans-serif;
        @media (max-width: 991px) {
          white-space: initial;
  }
        `;


const Div21 = styled.div`
        align-self: center;
        display: flex;
        margin-top: 35px;
        flex-direction: column;
        @media (max-width: 991px) {
          max-width: 100%;
        margin-top: 40px;
  }
        `;

const Div22 = styled.div`
        align-self: start;
        display: flex;
        gap: 8px;
        `;

const Div23 = styled.div`
        color: #ff7a00;
        text-align: right;
        flex-grow: 1;
        white-space: nowrap;
        font: 400 24px Oleo Script, sans-serif;
        @media (max-width: 991px) {
          white-space: initial;
  }
        `;

const Div24 = styled.div`
        align-self: stretch;
        margin-top: 38px;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div25 = styled.div`
        gap: 20px;
        display: flex;
        @media (max-width: 991px) {
          flex-direction: column;
        align-items: stretch;
        gap: 0px;
  }
        `;

const Column = styled.div`
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 61%;
        margin-left: 0px;
        @media (max-width: 991px) {
          width: 100%;
  }
        `;

const Div26 = styled.div`
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        @media (max-width: 991px) {
          max-width: 100%;
        margin-top: 33px;
  }
        `;

const Div27 = styled.div`
        color: var(--Title-color, #1f2127);
        align-self: stretch;
        font: 500 36px/48px Barlow, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div28 = styled.div`
        background-color: #ff7a00;
        align-self: stretch;
        margin-top: 23px;
        height: 3px;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div29 = styled.div`
        color: #242424;
        text-align: justify;
        align-self: stretch;
        margin:30px;
        margin-top: 25px;
        font: 400 20px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div30 = styled.div`
        align-self: stretch;
        display: flex;
        margin-top: 38px;
        justify-content: space-around;
        gap: 5px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;

const Div31 = styled.div`
        display: flex;
        justify-content: space-between;
        gap: 13px;
        `;


const Div32 = styled.div`
        color: #000;
        text-align: justify;
        align-self: start;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 18px Manrope, sans-serif;
        `;
const Div320 = styled.input`
        color: #000;
        text-align: justify;
        align-self: start;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 18px Manrope, sans-serif;
        `;

const Div33 = styled.div`
        display: flex;
        justify-content: space-between;
        gap: 13px;
        `;


const Div34 = styled.div`
        color: #000;
        text-align: justify;
        align-self: start;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 18px Manrope, sans-serif;
        `;
const Div340 = styled.input`
        color: #000;
        text-align: justify;
        align-self: start;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 18px Manrope, sans-serif;
        `;

const Div35 = styled.div`
        align-self: stretch;
        display: flex;
        margin-top: 21px;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;

const Div36 = styled.div`
        display: flex;
        justify-content: space-between;
        gap: 13px;
        `;

const Img7 = styled.img`
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 20px;
        overflow: hidden;
        max-width: 100%;
        `;

const Div37 = styled.div`
        color: #000;
        text-align: justify;
        align-self: start;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 18px Manrope, sans-serif;
        `;

const Div38 = styled.div`
        display: flex;
        justify-content: space-between;
        gap: 13px;
        `;

const Img8 = styled.img`
        aspect-ratio: 1;
        object-fit: contain;
        object-position: center;
        width: 20px;
        overflow: hidden;
        max-width: 100%;
        `;

const Div39 = styled.div`
        color: #000;
        text-align: justify;
        align-self: start;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 18px Manrope, sans-serif;
        `;

const Div40 = styled.div`
        color: #242424;
        align-self: stretch;
        margin-top: 44px;
        font: 400 16px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
        margin-top: 40px;
  }
        `;

const Div41 = styled.div`
        background-color: #ff7a00;
        align-self: stretch;
        margin-top: 30px;
        height: 1px;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div42 = styled.div`
        align-self: start;
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
        gap: 20px;
        `;

const Img9 = styled.img`
        aspect-ratio: 2.05;
        object-fit: contain;
        object-position: center;
        width: 121px;
        overflow: hidden;
        max-width: 100%;
        `;

const Div43 = styled.div`
        align-self: center;
        display: flex;
        flex-grow: 1;
        flex-basis: 0%;
        flex-direction: column;
        margin: auto 0;
        `;

const Div44 = styled.div`
        color: var(--Title-color, #1f2127);
        white-space: nowrap;
        font: 500 24px Barlow, sans-serif;
        @media (max-width: 991px) {
          white-space: initial;
  }
        `;

const Div45 = styled.div`
        color: var(--Paragraph-color, #5f6270);
        margin-top: 12px;
        font: 400 16px Manrope, sans-serif;
        `;

const Div46 = styled.div`
        align-self: center;
        display: flex;
        gap: 8px;
        margin: 107px 54px 0 0;
        @media (max-width: 991px) {
          margin: 40px 10px 0 0;
  }
        `;

const Div47 = styled.div`
        color: #ff7a00;
        text-align: right;
        flex-grow: 1;
        white-space: nowrap;
        font: 400 24px Oleo Script, sans-serif;
        @media (max-width: 991px) {
          white-space: initial;
  }
        `;

const Column2 = styled.div`
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 39%;
        @media (max-width: 991px) {
          width: 100%;
  }
        `;

const Div48 = styled.div`
        display: flex;
        margin:10px;
        margin-top: 20px;
        flex-direction: column;
        align-items: end;
        @media (max-width: 991px) {
          max-width: 95%;
        margin-top: 40px;
  }
        `;

const Img11 = styled.img`
        aspect-ratio: 1.66;
        object-fit: contain;
        object-position: center;
        width: 113px;
        overflow: hidden;
        max-width: 100%;
        `;

const Img12 = styled.img`
        aspect-ratio: 0.94;
        object-fit: contain;
        object-position: center;
        width: 100%;
        overflow: hidden;
        align-self: stretch;
        margin-top: 32px;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Img13 = styled.img`
        aspect-ratio: 1.66;
        object-fit: contain;
        object-position: center;
        width: 113px;
        overflow: hidden;
        align-self: start;
        margin-top: 32px;
        max-width: 100%;
        `;

const Div49 = styled.div`
        color: var(--Title-color, #1f2127);
        text-align: center;
        align-self: center;
        margin-top: 32px;
        font: 500 36px/133% Barlow, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div50 = styled.div`
        align-self: stretch;
        margin-top: 58px;
        @media (max-width: 991px) {
          max-width: 100%;
        margin-top: 40px;
  }
        `;

const Div51 = styled.div`
        gap: 20px;
        display: flex;
        @media (max-width: 991px) {
          flex-direction: column;
        align-items: stretch;
        gap: 0px;
  }
        `;

const Column3 = styled.div`
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 100%;
        margin-left: 0px;
        @media (max-width: 991px) {
          width: 100%;
  }
        `;

const Div52 = styled.div`
        display: flex;
        flex-direction: column;
        @media (max-width: 991px) {
          max-width: 100%;
        margin-top: 31px;
  }
        `;

const Div53 = styled.div`
        color: #242424;
        text-align: justify;
        margin:30px;
        font: 400 20px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div530 = styled.textarea`
        color: #242424;
        height:50vh;
        width:70vw;
        text-align: justify;
        margin:30px;
        font: 400 20px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;
const Div520 = styled.textarea`
        color: #242424;
        height:60vh;
        width:57vw;
        text-align: justify;
        margin:30px;
        font: 400 20px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;
const Div5200 = styled.textarea`
        color: #242424;
        height:40vh;
        width:57vw;
        text-align: justify;
        margin:30px;
        font: 400 20px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;
const Div5300 = styled.textarea`
        color: #242424;
        height:20vh;
        width:90vw;
        text-align: justify;
        margin:30px;
        font: 400 20px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div54 = styled.div`
        display: flex;
        margin-top: 25px;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;

const Div55 = styled.div`
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 8px;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div56 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;


const Div58 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div59 = styled.div`
        display: flex;
        margin-top: 23px;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;

const Div61 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div63 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div64 = styled.div`
        display: flex;
        margin-top: 23px;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;

const Div65 = styled.div`
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 8px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;


const Div66 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div68 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div70 = styled.div`
        background-color: #f5f5f5;
        align-self: stretch;
        display: flex;
        margin-top: 100px;
        flex-direction: column;
        padding: 50px 80px 50px 63px;
        @media (max-width: 991px) {
          max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
  }
        `;

const Div71 = styled.div`
        align-self: center;
        display: flex;
        margin-top: 37px;
        gap: 8px;
        `;

const Div72 = styled.div`
        color: #ff7a00;
        text-align: right;
        flex-grow: 1;
        white-space: nowrap;
        font: 400 24px Oleo Script, sans-serif;
        @media (max-width: 991px) {
          white-space: initial;
  }
        `;

const Div73 = styled.div`
        color: var(--Title-color, #1f2127);
        text-align: center;
        align-self: center;
        margin-top: 32px;
        font: 500 36px/133% Barlow, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;


const Div74 = styled.div`
        align-self: stretch;
        margin: 48px 55px 30px 0;
        @media (max-width: 991px) {
          max-width: 100%;
        margin: 40px 10px 0 0;
  }
        `;

const Div75 = styled.div`
        gap: 20px;
        display: flex;
        @media (max-width: 991px) {
          flex-direction: column;
        align-items: stretch;
        gap: 0px;
  }
        `;

const Column5 = styled.div`
        display: flex;
        flex-direction: column;
        line-height: normal;
        width: 100%;
        margin-left: 0px;
        @media (max-width: 991px) {
          width: 100%;
  }
        `;

const Div76 = styled.div`
        display: flex;
        margin-top: 9px;
        flex-grow: 1;
        flex-direction: column;
        align-items: end;
        @media (max-width: 991px) {
          max-width: 100%;
        margin-top: 39px;
  }
        `;

const Div77 = styled.div`
        display: flex;
        max-width: 100%;
        flex-direction: column;
        `;

const Div78 = styled.div`
        color: #242424;
        text-align: justify;
        font: 400 20px/32px Manrope, sans-serif;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;


const Div89 = styled.div`
        align-self: stretch;
        display: flex;
        margin-top: 13px;
        align-items: start;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;

const Div90 = styled.div`
        display: flex;
        margin-top: 10px;
        flex-grow: 1;
        flex-basis: 0%;
        flex-direction: column;
        @media (max-width: 991px) {
          max-width: 100%;
  }
        `;

const Div91 = styled.div`
        display: flex;
        justify-content: space-between;
        gap: 20px;
        @media (max-width: 991px) {
          max-width: 100%;
        flex-wrap: wrap;
  }
        `;

const Div92 = styled.div`
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 12px;
        `;
const Div93 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        `;

const Div94 = styled.div`
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 12px;
        `;
const Div95 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        `;
const Div98 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;
        `;

const Div100 = styled.div`
        color: #000;
        align-self: stretch;
        flex-grow: 1;
        flex-basis: auto;
        font: 400 20px Manrope, sans-serif;

        `;

const Div560 = styled.div`
  color: #383838;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  background-color: #f7f7f7;
  align-self: stretch;
  cursor:pointer;
  margin-top: 48px;
  justify-content: center;
  text-align:center;
  align-items: center;
  padding: 34px 60px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
   user-select: none;
  font: 500 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    margin-top: 40px;
    padding: 20 20px;
  }
`;


export default EditAboutPage;
