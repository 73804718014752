import * as React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import FEHHeader from "./FEHHeader";
function Contact(props) {
  return (
    <Div>
      <FEHHeader />
      <Div14>
        <Div15>
          <Div16>Contact</Div16>
        </Div15>
      </Div14>
      <Div21>
        <Div22>
          <Div23>
            <Div24>
              <Column>
                <Div25>
                  <Div26>
                    <span style={{ fontFamily: 'Manrope, sans-serif', fontWeight: '500' }}>
                      First name
                    </span>
                    <span style={{ fontFamily: 'Manrope, sans-serif', fontWeight: '500', color: 'rgba(200,16,46,1)' }}>
                      *
                    </span>
                  </Div26>
                  <Div27 placeholder="First name" />
                  <Div28>
                    <span style={{ fontFamily: 'Manrope, sans-serif', fontWeight: '500' }}>
                      Last name
                    </span>
                    <span style={{ fontFamily: 'Manrope, sans-serif', fontWeight: '500', color: 'rgba(200,16,46,1)' }}>
                      *
                    </span>
                  </Div28>
                  <Div29 placeholder="Last name" />
                  <Div30>
                    <span style={{ fontFamily: 'Manrope, sans-serif', fontWeight: '500' }}>
                      Mobile number
                    </span>
                    <span style={{ fontFamily: 'Manrope, sans-serif', fontWeight: '500', color: 'rgba(200,16,46,1)' }}>
                      *
                    </span>
                  </Div30>
                  <Div31 placeholder="Mobile number" />
                  <Div32>Email address (Optional)</Div32>
                  <Div33 placeholder="Email address" />
                  <Div41>
                    <Div42>
                      <Div43 placeholder="Write Message" />

                    </Div42>

                  </Div41>

                </Div25>
                <Div46>
                  <Div47 onClick={() => alert('Thank you for contacting us')}>Send message</Div47>
                  {/* <Img7
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/349cb087bf2af631429d163a818c07a72a81f01cd01c7d355af212d98795175e?"
                        />
                         */}
                  <Div380>
                    <FaFacebookF
                      onClick={() => {
                        const url = 'https://www.facebook.com/georgemacleangm?mibextid=LQQJ4d';
                        window.open(url, '_blank');
                      }}
                      style={{ width: '50px', height: '50px', padding: '10px', cursor: 'pointer' }} />
                    <FaXTwitter
                      onClick={() => {
                        const url = 'https://twitter.com/@georgemacleangm';
                        window.open(url, '_blank');
                      }}
                      style={{ width: '50px', height: '50px', padding: '10px', cursor: 'pointer' }} />
                    <FaLinkedinIn
                      onClick={() => {
                        const url = 'https://www.linkedin.com/in/george-maclean-8b98b254?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app';
                        window.open(url, '_blank');
                      }}
                      style={{ width: '50px', height: '50px', padding: '10px', cursor: 'pointer' }} />
                    <FaInstagram
                      onClick={() => {
                        const url = 'instagram.com/georgemaclean';
                        window.open(url, '_blank');
                      }}
                      style={{ width: '50px', height: '50px', padding: '10px', cursor: 'pointer' }} />
                  </Div380>
                </Div46>
              </Column>


            </Div24>
            <Column2>
              <Div34>
                <Div35 onClick={(e) => { window.location.href = 'mailto:info@georgemaclean.com.au'; }}
                >
                  <Div36>
                    {/* <Img4
                                                loading="lazy"
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a06abbfb39eeb26927b69128da334c4774cdf49c81c9adc10628d97b726fe36c?"
                                            /> */}
                    <FaEnvelope style={{ width: '50px', height: '50px', padding: '10px', cursor: 'pointer', color: '#2972c1' }} />
                    <Div37>info@georgemaclean.com.au</Div37>
                  </Div36>
                </Div35>
                {/* <Div38 onClick={(e) => { window.location.href = 'tel:+61 402813431'; }} >
                  <Div39>
                    {/* <Img5
                                                loading="lazy"
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/51cba6e238f742b4c617c90fafcd2a824e9c8f8393473cd8f94b9cedbcbb50f3?"
                                            /> * /}
                <FaPhone style={{ width: '50px', height: '50px', padding: '10px', cursor: 'pointer', color: '#2972c1', rotate: '90deg' }} />
                <Div40>+61 402813431</Div40>
              </Div39>
            </Div38> */}
              </Div34>
            </Column2>
          </Div23>

        </Div22>
      </Div21 >
      <Footer />
    </Div >
  );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;


const Div14 = styled.div`
  background-color: #183862;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const Div15 = styled.div`
  display: flex;
  width: 137px;
  max-width: 100%;
  flex-direction: column;
  margin: 42px 0 63px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const Div16 = styled.div`
  color: #fff;
  text-align: center;
  letter-spacing: 0.72px;
  white-space: nowrap;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;


const Div21 = styled.div`
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div22 = styled.div`
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  margin: 50px 0;
  padding: 50px 40px;
  width:100%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 0;
    padding: 0 20px;
  }
`;

const Div23 = styled.div`
  margin-top: 19px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div24 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 95%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div25 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Div26 = styled.div`
  color: #c8102e;
  font: 400 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div27 = styled.input`
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  width:50%;
  display: flex;
  margin-top: 21px;
  height: 41px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div28 = styled.div`
  color: #c8102e;
  margin-top: 37px;
  font: 400 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div29 = styled.input`
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  width:50%;
  display: flex;
  margin-top: 21px;
  height: 41px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div30 = styled.div`
  color: #c8102e;
  margin-top: 37px;
  font: 400 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div31 = styled.input`
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  width:50%;
  display: flex;
  margin-top: 21px;
  height: 41px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div32 = styled.div`
  color: rgba(43, 44, 45, 0.7);
  margin-top: 36px;
  font: 500 16px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div33 = styled.input`
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  width:50%;
  display: flex;
  margin-top: 17px;
  height: 41px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div34 = styled.div`
  display: flex;
  margin-top: 33px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div35 = styled.div`
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  display: flex;
  cursor:pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 80%;
    padding: 0 20px;
  }
`;

const Div36 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 23px 0;
`;

const Img4 = styled.img`
  aspect-ratio: 1.3;
  object-fit: contain;
  object-position: center;
  width: 52px;
  fill: #2972c1;
  overflow: hidden;
  max-width: 100%;
`;

const Div37 = styled.div`
  color: #303030;
  align-self: stretch;
  margin-top: 40px;
  white-space: nowrap;
  font: 500 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div38 = styled.div`
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  display: flex;
  cursor:pointer;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 80%;
    padding: 0 20px;
  }
`;

const Div380 = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 40%;
  @media (max-width: 991px) {
    margin-top: 5px;
  }
`;

const Div39 = styled.div`
  display: flex;
  width: 190px;
  max-width: 80%;
  flex-direction: column;
  align-items: center;
  margin: 21px 0 29px;
`;

const Img5 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 42px;
  fill: #2972c1;
  overflow: hidden;
  max-width: 100%;
`;

const Div40 = styled.div`
  color: #303030;
  align-self: stretch;
  margin-top: 40px;
  white-space: nowrap;
  font: 500 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div41 = styled.div`
  margin-top: 17px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div42 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    gap: 0px;
  }
`;

const Div43 = styled.input`
  color: rgba(43, 44, 45, 0.7);
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  background-color: #fdfdfd;
  margin-top: 15px;
  flex-grow: 1;
  align-items: start;
  width: 100%;
  padding: 30px 60px 154px 31px;
  font: 500 16px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px 40px;
  }
`;

const Div46 = styled.div`
  display: flex;
  padding-right: 80px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 46px 0 72px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
    margin: 40px 0;
  }
`;

const Div47 = styled.div`
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 4px;

  cursor:pointer;
  background-color: #2972c1;
  justify-content: center;
  padding: 23px 44px;
  font: 500 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 20 20px;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 5.15;
  object-fit: contain;
  object-position: center;
  width: 206px;
  overflow: hidden;
  max-width: 100%;
`;

export default Contact;
