import React, { useEffect, useRef, useState } from 'react';
import { useScrollDirection } from './useScrollDirection';

const FEHHeader = () => {
    const initialLoggedInState = localStorage.getItem('isLoggedIn') ?? false;
    const initialLoggedInTime = localStorage.getItem('timestamp') ?? false;
    let ref = useRef(0);
    let currTime = new Date()
    const [isLoggedIn, setIsLoggedIn] = useState(initialLoggedInState === 'true');



    useEffect(() => {
        let tempCompare = null
        console.log('not teur', initialLoggedInTime, (new Date() / 1000 / 60) - (new Date(initialLoggedInTime) / 1000 / 60))
        try {
            if (initialLoggedInTime == 'false') {
                console.log('not teur', initialLoggedInTime, (new Date() / 1000 / 60) - (new Date(initialLoggedInTime) / 1000 / 60))
                tempCompare = JSON.parse(initialLoggedInTime)
            }

        } catch (e) {
            tempCompare = initialLoggedInTime
        }

        if (tempCompare == false) {
            setIsLoggedIn(false)
        }
    }, []);
    // if (initialLoggedInTime != false) {
    //     // setIsLoggedIn(false)
    //     console.log(initialLoggedInTime, 'coming here 1',)
    // }

    if (initialLoggedInTime != false) {
        console.log('coming here 2', initialLoggedInTime)
        if ((new Date() / 1000 / 60) - (new Date(initialLoggedInTime) / 1000 / 60) > 120) {
            localStorage.setItem('timestamp', false);
            setIsLoggedIn(false)
        }
    }
    // useEffect(() => {
    //     localStorage.setItem('isLoggedIn', isLoggedIn);
    // }, [isLoggedIn])
    const scrollDirection = useScrollDirection(ref);
    return (
        <div>
            {scrollDirection < 108 && <header style={{}} >
                <nav>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/About">About</a></li>
                        <li><a href="/Blog">Blog</a></li>
                        <li><a href="/Quotes">Quotes</a></li>
                        <li><a href="/Contact">Contact</a></li>
                        <li>{isLoggedIn ? <a href="/ShowMenu">Menu</a> : <a href="/Login">Login</a>}</li>
                    </ul>
                </nav>
            </header>}
        </div>
    );
}

export default FEHHeader;
