import CryptoJS from 'crypto-js';

const encryptData = (originalText, secretKey) => {
    try {
        const encrypted = CryptoJS.AES.encrypt(originalText, secretKey).toString();
        return encrypted;
    } catch (error) {
        console.error('Encryption error:', error);
        return null;
    }
};

export default encryptData;