import React, { useEffect, useState } from "react";
import styled from "styled-components";
import blog from './asset/img/blog.png'
import Footer from "./Footer";
import { Link, Route, Routes, useParams } from 'react-router-dom'
import FEHHeader from "./FEHHeader";
import { useNavigate } from 'react-router-dom';

function BlogDetails(props) {
  const { id } = useParams();
  const [name, setname] = useState(null)
  const [email, setemail] = useState(null)
  const [website, setwebsite] = useState(null)
  const [comment, setcomment] = useState(null)
  const [refetch, setrefetch] = useState(false)
  const [isReply, setIsReply] = useState(null)
  console.log(id)
  // bolgId
  // blogFetch
  //   fetch('https://httpbin.org/post', {
  //   method: 'POST',
  //   headers: {
  //     'Accept': 'application/json, text/plain, */*',
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({a: 7, str: 'Some string: &=&'})
  // }).then(res => res.json())
  //   .then(res => console.log(res));


  const history = useNavigate();
  const [blogs, setBlogs] = useState(null)
  useEffect(() => {
    console.log('blogs access')
    // fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/blogs').then(async res => {
    //   let k = await res.json()
    //   console.log('this is res', k)
    //   setBlogs(k.data.blogs)
    // }).catch(error => {
    //   console.log(error)
    // })

    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/blogFetch', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ blogId: id })
    }).then(res => res.json())
      .then(res => {
        console.log(res)
        setBlogs(res.data.blogData)
      });

  }, [refetch]);

  return (
    <Div>
      <FEHHeader />
      <Div15>
        <Div16>
          <Div17>Blog details</Div17>
        </Div16>
      </Div15>
      {!blogs && <div>Loading</div>}
      {blogs && <Div22>
        <Img4
          loading="lazy"
          srcSet={blog}
        />
        <Div23>{blogs.date}- by {blogs.author}</Div23>
        <Div24>
          {blogs.heading}
        </Div24>
        <Div25>
          {blogs.text}
        </Div25>

        <Div32>{blogs?.comments?.length ? `(${blogs?.comments?.length}) Comments` : 'No Comments yet'} </Div32>


        <Div33>
          {blogs?.comments?.map(x => <Div34>
            <Div35>
              {/* comment:"contetn is good"
              commentId:"6BSpOwp2Ms3rmVKgXOpd"
              email:"ram@gmai;.com"
              name:"ram"
              website:"ram.com" */}

              <Div36>
                <Div37>{x.name}</Div37>
                <Div38>{x.date}</Div38>
              </Div36>
              <Div39 onClick={() => {
                setIsReply(x)
                console.log(isReply)
              }}>Reply</Div39>
            </Div35>
            <Div40>
              {x.comment}
            </Div40>
            {x?.replys && x?.replys.map(y => <Div340>
              <Div35>
                <Div360>
                  <Div37>{y.name}</Div37>
                  <Div38>{y.date}</Div38>
                </Div360>
              </Div35>
              <Div40>
                {y.comment}
              </Div40>
            </Div340>)}

          </Div34>)}
        </Div33>

        <Div57>{isReply ? <div style={{ display: 'flex' }}>Replying to {isReply.name} / <div style={{ color: "blueviolet", cursor: 'pointer' }} onClick={() => setIsReply(null)} > Write as comment</div></div> : <div>Write a comment</div>}</Div57>
        <Div58>
          <Div59 value={name} onChange={e => setname(e.target.value)} placeholder="Your name"></Div59>
          <Div60 value={email} onChange={e => setemail(e.target.value)} placeholder="Your e-mail"></Div60>
          <Div61 value={website} onChange={e => setwebsite(e.target.value)} placeholder="Your website"></Div61>
        </Div58>
        <Div62 value={comment} onChange={e => setcomment(e.target.value)} placeholder="Your comment"></Div62>
        <Div63 onClick={() => {
          console.log(
            name,
            email,
            website,
            comment,
            'this is values '
          )
          let comments = {
            name: name,
            email: email,
            website: website,
            comment: comment,
            date: new Date().toDateString()
          }
          // blogId
          if (isReply == null) {
            fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/writeBlogComment', {
              method: 'POST',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ blogId: id, comment: comments })
            }).then(res => res.json())
              .then(res => {
                console.log(res)
                // setBlogs(res.data.blogData)
                setrefetch(!refetch)
              }).catch(e => {
                console.log(e)
                alert('Something went wrong,please try again')
              });
          }
          else {
            fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/writeBlogCommentReply', {
              method: 'POST',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ blogId: id, comment: comments, commentId: isReply.commentId })
            }).then(res => res.json())
              .then(res => {
                console.log(res)
                // setBlogs(res.data.blogData)
                setrefetch(!refetch)
              }).catch(e => {
                console.log(e)
                alert('Something went wrong,please try again')
              });
          }
        }}>Submit</Div63>
      </Div22>}
      <Footer />
    </Div>
  );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Div15 = styled.div`
  background-color: #183862;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top:20px;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div16 = styled.div`
  display: flex;
  width: 187px;
  max-width: 100%;
  flex-direction: column;
  margin: 41px 0 59px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const Div17 = styled.div`
  color: #fff;
  text-align: center;
  letter-spacing: 0.72px;
  white-space: nowrap;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
const Div22 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 100px;
  max-width: 1410px;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1.97;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  align-self: stretch;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div23 = styled.div`
  color: #5f5f5f;
  align-self: stretch;
  margin-top: 38px;
  font: 500 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div24 = styled.div`
  color: #0a0d14;
  align-self: stretch;
  margin-top: 29px;
  font: 600 36px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div25 = styled.div`
  color: #313131;
  text-align: justify;
  align-self: stretch;
  margin:10px;
  margin-top: 32px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div26 = styled.div`
  color: #313131;
  text-align: justify;
  align-self: stretch;
  margin-top: 23px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div27 = styled.div`
  color: #1b1b1b;
  border-radius: 5px;
  background-color: #f0f0f0;
  align-self: stretch;
  margin-top: 33px;
  justify-content: center;
  padding: 32px 34px;
  font: 400 22px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div28 = styled.div`
  color: #313131;
  text-align: justify;
  align-self: stretch;
  margin-top: 38px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div29 = styled.div`
  align-self: stretch;
  margin-top: 33px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div30 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img5 = styled.img`
  aspect-ratio: 1.32;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img6 = styled.img`
  aspect-ratio: 1.32;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 1.32;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Div31 = styled.div`
  color: #313131;
  text-align: justify;
  align-self: stretch;
  margin-top: 38px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div32 = styled.div`
  color: #000;
  align-self: stretch;
  margin-top: 54px;
  font: 500 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div33 = styled.div`
  align-self: stretch;
  margin-top: 35px;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div34 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  align-items: start;
  border:solid #d1d1d1 1px;
  border-radius:15px;
  padding:30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div340 = styled.div`
  display: flex;
  margin-top: 16px;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  align-items: start;
  margin-left:30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div35 = styled.div`
  display: flex;
  gap: 2px;
`;

const Div36 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  width:350px;
  flex-direction: column;
`;

const Div360 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  width:350px;
  align-items:baseline;
  justify-content:space-between;
`;

const Div37 = styled.div`
  color: #313131;
  font: 600 18px Manrope, sans-serif;
`;

const Div38 = styled.div`
  color: #3b3b3b;
  margin-top: 13px;
  white-space: nowrap;
  font: 400 14px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div39 = styled.div`
  color: #313131;
  align-self: start;
  cursor:pointer;
  flex-grow: 1;
  white-space: nowrap;
  font: 500 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div40 = styled.div`
  color: #212121;
  align-self: stretch;
  margin-top: 11px;
  font: 400 16px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;


const Div57 = styled.div`
  color: #000;
  align-self: stretch;
  margin-top: 42px;
  font: 500 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div58 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 39px;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div59 = styled.input`
  color: #696969;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background-color: #f7f7f7;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  padding: 23px 30px 23px 21px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div60 = styled.input`
  color: #696969;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background-color: #f7f7f7;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  padding: 23px 30px 23px 21px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div61 = styled.input`
  color: #696969;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background-color: #f7f7f7;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  padding: 23px 30px 23px 21px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div62 = styled.textarea`
  color: #696969;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  background-color: #f7f7f7;
  align-self: stretch;
  margin-top: 32px;
  align-items: start;
  padding: 24px 60px 103px 21px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    padding: 0 20px 40px;
  }
`;

const Div63 = styled.div`
  color: #fff;
  white-space: nowrap;
  border-radius: 4px;
  cursor:pointer;
  background-color: #2972c1;
  align-self: start;
  margin-top: 48px;
  justify-content: center;
  padding: 24px 56px;
  font: 500 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

export default BlogDetails;
