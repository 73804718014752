import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FEHHeader from "./FEHHeader";
import william from './asset/img/william.jpg'
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import background from './asset/img/background.png'
import group from './asset/img/group.png'
import logo from './asset/img/logo.png'
import sign from './asset/img/sign.png'
import george1 from './asset/img/george1.png'
import george2 from './asset/img/george2.png'
import george4 from './asset/img/george4.png'
import myVideo from './asset/img/Animal_Beak.mp4';
import peace from './asset/img/peace.png';
import love from './asset/img/love.png';
import integrity from './asset/img/integrity.png';
import equality from './asset/img/equality.png';
import { useHistory, useNavigate } from 'react-router-dom'
import FooterHome from "./FooterHome";

function HomePage(props) {
  let navigate = useNavigate();
  const routeChange = (path) => {
    // let path = `contact`;
    navigate(path);
  }

  const [content, setContent] = useState(null)

  useEffect(() => {
    // fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchAboutPage').
    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchHomePage', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }).then(res => res.json())
      .then(res => {
        console.log(res.data)
        // setweeklypost(res.data.weeklyPost.posts)
        setContent(res.data.homePage)
        // setBlogs(res.data.blogData)
        // setrefetch(!refetch)
      }).catch(e => {
        console.log(e)
        // alert('Something went wrong,please try again')
      });

  }, []);

  return (
    <div style={{ width: '100%', contain: 'content' }}>
      <FEHHeader />
      <Div>
        <div className="video-background">
          <video className='video'
            src={myVideo} type="video/mp4"
            autoPlay loop muted style={{
              position: 'absolute',
              width: '100vw',
              height: '115vh',
              objectFit: 'cover',
              opacity: '.6'
            }} />
          <div></div>
          <div className="content">

            <Div2>

              {/* <div className="video-container">
            <video autoPlay muted loop id="video">
              <source src={myVideo} type="video/mp4" />
            </video>
          </div> */}


              <Div3>
                <Div5>
                  <Div6>
                  </Div6>
                  <Div12>

                    <Img3
                      loading="lazy"
                      srcSet={logo}
                    />
                  </Div12>
                </Div5>
                <Div15>
                  <Div16>
                    <Column>
                      <Div17>
                        <Div18>George Maclean</Div18>
                        <Div19>Join me on my Journey of poetry.</Div19>
                        {content && <Div20>{content.headingText}</Div20>}
                        <Div21 onClick={() => routeChange('Blog')}>Discover More</Div21>
                      </Div17>
                    </Column>
                    <Column2>
                      <Div22>
                        <Img5
                          loading="lazy"
                          srcSet={group}
                        />
                      </Div22>
                    </Column2>
                  </Div16>
                </Div15>
                <Div23>
                  <Div24>
                    {/* <Column3>
                      <Div25>
                        <Img6
                          loading="lazy"
                          srcSet={sign}
                        />
                      </Div25>
                    </Column3> */}
                    <Column4>
                      <Div26>
                        <Div27>
                          <Column5>
                            <Div28>
                              <Div29>

                                {content && <Div31>{content.punchline1}</Div31>}
                              </Div29>
                              <Div32>
                                {content && <Div34>{content.punchline2}</Div34>}
                              </Div32>
                              <Div35>
                                {content && <Div37>{content.punchline3}</Div37>}
                              </Div35>
                            </Div28>
                          </Column5>
                          <Column6>
                            <Div38>
                              <FaFacebookF
                                onClick={() => {
                                  const url = 'https://www.facebook.com/georgemacleangm?mibextid=LQQJ4d';
                                  window.open(url, '_blank');
                                }}
                                style={{ width: '30px', height: '30px', padding: '10px', cursor: 'pointer' }} />
                              <FaXTwitter
                                onClick={() => {
                                  const url = 'https://twitter.com/@georgemacleangm';
                                  window.open(url, '_blank');
                                }}
                                style={{ width: '30px', height: '30px', padding: '10px', cursor: 'pointer' }} />
                              <FaLinkedinIn
                                onClick={() => {
                                  const url = 'https://www.linkedin.com/in/george-maclean-8b98b254?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app';
                                  window.open(url, '_blank');
                                }}
                                style={{ width: '30px', height: '30px', padding: '10px', cursor: 'pointer' }} />
                              <FaInstagram
                                onClick={() => {
                                  const url = 'instagram.com/georgemaclean';
                                  window.open(url, '_blank');
                                }}
                                style={{ width: '30px', height: '30px', padding: '10px', cursor: 'pointer' }} />
                            </Div38>
                          </Column6>
                        </Div27>
                      </Div26>
                    </Column4>
                  </Div24>
                </Div23>
              </Div3>
            </Div2>

          </div>

          {/* </video> */}
        </div>
        <Div39>
          <Div40>
            {/* <Column7>
              <Div41>
                <Img8
                  loading="lazy"
                  srcSet={george1}
                />
              </Div41>
            </Column7> */}
            <Column8>
              <Div43>
                <Div44>
                  <Div45>About Me</Div45>
                  <div style={{
                    color: "rgba(255,122,0,1)",
                    fontSize: '21px',
                    fontWeight: '800',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    overflow: 'hidden',
                    alignSelf: 'center',
                  }}>. . .</div>
                </Div44>
                {content && <Div46>{content.aboutMeHeading}</Div46>}
                {content && <Div47>
                  {content.aboutMeText}
                  <span style={{ color: "rgba(255,122,0,1)" }}>
                    <span style={{ color: "rgba(255,122,0,1)" }}>{"  "}see more...</span>
                  </span>
                </Div47>}
                <Div48>
                  <Div49>

                    <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                    {content && <Div50>{content.lines[0]}</Div50>}
                  </Div49>
                  <Div51>
                    <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                    {content && <Div50>{content.lines[1]}</Div50>}
                  </Div51>
                </Div48>
                <Div53>
                  <Div54>
                    <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                    {content && <Div50>{content.lines[2]}</Div50>}
                  </Div54>
                  <Div56>
                    <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                    {content && <Div50>{content.lines[3]}</Div50>}
                  </Div56>
                </Div53>
                <Div58>
                  <Div59>
                    <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                    {content && <Div50>{content.lines[4]}</Div50>}
                  </Div59>
                  <Div61>
                    <FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                    {content && <Div50>{content.lines[5]}</Div50>}
                  </Div61>
                </Div58>

                <Div68 onClick={() => routeChange('Contact')} href="mailto:george@medvatic.com">Contact me</Div68>
              </Div43>
            </Column8>
          </Div40>
        </Div39>
        <Div69>
          <Div70>
            <Column9>
              <Div71>
                <Div72>
                  <Div73>My Mission</Div73>
                  <div style={{
                    color: "rgba(255,122,0,1)",
                    fontSize: '15px',
                    fontWeight: '800',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    overflow: 'hidden',
                    alignSelf: 'center',
                  }}>. . .</div>
                </Div72>
                <Div74></Div74>
                {content && <Div47a>
                  {content.myMissionText}
                  {/* <span style={{ color: "rgba(255,122,0,1)" }}>
                    <span style={{ color: "rgba(255,122,0,1)" }}>see more..</span>
                  </span> */}
                </Div47a>}
                <Div76>
                  <Div77>
                    <Column10>
                      <Div78>
                        {/* <Div79><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          <Div80>Poetry's Pulse, Peace's Rhythm: A Purposeful Odyssey</Div80>
                        </Div79>
                        <Div81><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          <Div82>Radiate Peace, Inspire Creativity: Your Journey Starts with a Pen.</Div82>
                        </Div81>
                        <Div83><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          <Div84>Beyond Language, Beyond Borders: A Poetic Expedition into Purposeful Living.</Div84>
                        </Div83>
                        <Div85><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          <Div86>Verses of Grace, Stanzas of Purpose: Poet, Peacemaker, Pathfinder.</Div86>
                        </Div85>
                        <Div87><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          <Div88>Penning Peace, Crafting Purpose: A Symphony of Self-Discovery.</Div88>
                        </Div87> */}
                      </Div78>
                    </Column10>
                  </Div77>
                </Div76>
              </Div71>
            </Column9>
            {/* <Column12>
              <Img26
                loading="lazy"
                srcSet={george2}
              />
            </Column12> */}
          </Div70>
        </Div69>

        <Div69>
          <Div70>
            <Column9>
              <Div71>
                <Div72>
                  <Div73>My Vision</Div73>
                  <div style={{
                    color: "rgba(255,122,0,1)",
                    fontSize: '15px',
                    fontWeight: '800',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    overflow: 'hidden',
                    alignSelf: 'center',
                  }}>. . .</div>
                </Div72>
                <Div74>I envision a world where</Div74>

                <Div76>
                  <Div77>
                    <Column10>
                      <Div78>
                        <Div79><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          {content && <Div80>{content.envision[0]}</Div80>}
                        </Div79>
                        <Div81><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          {content && <Div82>{content.envision[1]}</Div82>}
                        </Div81>
                        <Div83><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          {content && <Div84>{content.envision[2]}</Div84>}
                        </Div83>
                        <Div85><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          {content && <Div86>{content.envision[3]}</Div86>}
                        </Div85>
                        <Div87><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          {content && <Div88>{content.envision[4]}</Div88>}
                        </Div87>
                        <Div87><FaCheckCircle style={{ width: '30px', height: '30px', color: 'blue' }} />
                          {content && <Div88>{content.envision[5]}</Div88>}
                        </Div87>
                      </Div78>
                    </Column10>
                  </Div77>
                </Div76>
                {content && <Div47>
                  {content.envisionText}
                </Div47>}
              </Div71>
            </Column9>
            {/* <Column12>
              <Img26
                loading="lazy"
                srcSet={george2}
              />
            </Column12> */}
          </Div70>
        </Div69>
        <Div90>
          <Div91>
            {/* <Column13>
              <Img27
                loading="lazy"
                srcSet={george4}
              />
            </Column13> */}
            <Column14>
              <div className="video-backgrounds">
                <video className='video'
                  src={myVideo} type="video/mp4"
                  autoPlay loop muted />
                <div>
                  <Div92>
                    <Div93>
                      {/* <Div94>Press Conferences</Div94>
                      <Div95>Meeting with William</Div95>
                      <Div96>
                        Had a great meeting with William recently! We talked about everything from the power of poetry to promoting peace and grace in the world. It was a deep dive into creativity and purpose.

                        We explored how poetry, as a universal language, can connect people from all walks of life. William and I share a vision for a more peaceful world and brainstormed some cool ideas for collaborations to make it happen.
                      </Div96> */}
                    </Div93>
                  </Div92>
                </div>

              </div>
            </Column14>
          </Div91>
        </Div90>
        <Div97>
          <DivHead>Pillars of Peace: A Journey of Love, Freedom, Integrity,  equality and Justice </DivHead>
          <Div98>
            {/* <Column15>
              <Div99>
                {/* <FaStar style={{ color: 'rgba(255,122,0,1)', width: '50px', height: '50px' }} /> * /}
                <Img27
                  loading="lazy"
                  srcSet={peace}
                />
                <Div100>Peace</Div100>
                <Div101>
                  Peace is a tranquil lake, undisturbed by the winds of conflict, reflecting the diversity of the surrounding landscape in its calm waters.
                </Div101>
              </Div99>
            </Column15> */}
            {content && [
              {
                heading: 'Peace', subtext: content.bottomContainer.Peace, img: peace
              },
              { heading: 'Integrity and Ethics', subtext: content.bottomContainer.Integrity, img: integrity },
              { heading: 'Love and Freedom', subtext: content.bottomContainer.love, img: love },
              { heading: 'Equality and Justice', subtext: content.bottomContainer.equality, img: equality },
            ].map(x => <Column16>
              <Div102>
                {/* <FaStar style={{ color: 'rgba(255,122,0,1)', width: '50px', height: '50px' }} /> */}
                <Img27
                  loading="lazy"
                  srcSet={x.img}
                />
                <Div103>{x.heading}</Div103>
                <Div104>
                  {x.subtext}
                </Div104>
              </Div102>
            </Column16>)}
            {/* <Column16>
              <Div102>
                {/* <FaStar style={{ color: 'rgba(255,122,0,1)', width: '50px', height: '50px' }} /> * / }
                <Img27
                  loading="lazy"
                  srcSet={integrity}
                />
                <Div103>Integrity and Ethics</Div103>
                <Div104>
                  Integrity and ethics are the compass of my soul, guiding me through the stormy seas of life towards the lighthouse of honesty and accountability.
                </Div104>
              </Div102>
            </Column16>
            <Column17>
              <Div105>
                {/* <FaStar style={{ color: 'rgba(255,122,0,1)', width: '50px', height: '50px' }} /> * /}
                <Img27
                  loading="lazy"
                  srcSet={love}
                />
                <Div106>Love and Freedom</Div106>
                <Div107>
                  Love is a river, flowing freely and unconditionally towards the ocean of unity, while freedom is a bird, soaring high in the boundless sky of expression and choice.
                </Div107>
              </Div105>
            </Column17>
            <Column18>
              <Div108>
                {/* <FaStar style={{ color: 'rgba(255,122,0,1)', width: '50px', height: '50px' }} /> * /}
                <Img27
                  loading="lazy"
                  srcSet={equality}
                />
                <Div109>Equality and Justice</Div109>
                <Div110>
                  Equality and justice are the roots of my belief tree, deeply embedded in the soil of respect and dignity, supporting the branches of advocacy for the marginalized and oppressed.
                </Div110>
              </Div108>
            </Column18> */}
          </Div98>
        </Div97>
        <FooterHome />
      </Div>
    </div >
  );
}

const Div = styled.div`
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  margin-top:6%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top:16%;
    flex-wrap: wrap;
  }
`;

const Div2 = styled.div`
  
  background-position: center;
  align-self: stretch;
  display: flex;
  object-fit:cover;
  height:100%;
  top:0;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  position:absolute;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div4 = styled.div`
  background-color: #ff7a00;
  align-self: start;
  display: flex;
  width: 56px;
  height: 8px;
  flex-direction: column;
`;

const Div5 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 14px;
  width: 100vw;
  padding-right: 80px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const Div6 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Div12 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top:8%;
`;

const Img3 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 82px;
  overflow: hidden;
  align-self: stretch;
  max-width: 100%;
`;

const Div15 = styled.div`
  align-self: stretch;
  margin-top: 47px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div16 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 53%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div17 = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

const Div18 = styled.div`
  color: #ff7a00;
  align-self: stretch;
  font: 600 24px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div19 = styled.div`
  color: #000;
  align-self: stretch;
  margin-top: 38px;
  font: 700 80px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    width: 100%;
    font-size: 40px;
  }
`;

const Div20 = styled.div`
  color: #000;
  align-self: stretch;
  margin-top: 22px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    width:95%;
  }
`;

const Div21 = styled.div`
  color: #fff;
  cursor:pointer;
  white-space: nowrap;
  border-radius: 5px;
  background-color: #ff7a00;
  align-self: start;
  justify-content: center;
  padding: 20px 27px;
  font: 500 18px/178% Poppins, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 47%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div22 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: end;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;



const Img5 = styled.img`
  aspect-ratio: 0.92;
  object-fit: contain;
  object-position: center;
  width: 594px;
  fill: #ff7a00;
  overflow: hidden;
  align-self: start;
  max-width: 100%;
`;

const Div23 = styled.div`
  align-self: center;
  width: 100%;
  max-width: 1425px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div24 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column3 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div25 = styled.div`
  border-radius: 0px 0px 0px 0px;
  background-color: rgba(255, 122.3999972641468, 0, 1);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 60px 24px 51px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Img6 = styled.img`
  object-fit: contain;
  object-position: center;
  width: 246px;
  overflow: hidden;
  align-self:start;
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 95%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div26 = styled.div`
  background-color: rgba(
    18.000000827014446,
    46.000001057982445,
    85.0000025331974,
    1
  );
  flex-grow: 1;
  padding-left: 37px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
  }
`;

const Div27 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 62%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 87%;
  }
`;

const Div28 = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div29 = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div31 = styled.div`
  color: #bdbdbd;
  align-self: stretch;
  flex-grow: 1;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div32 = styled.div`
  display: flex;
  margin-top: 11px;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;


const Div34 = styled.div`
  color: #bdbdbd;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div35 = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div37 = styled.div`
  color: #bdbdbd;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 38%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div38 = styled.div`
  display:flex;
  background-color: rgba(
    41.00000135600567,
    114.0000008046627,
    193.00000369548798,
    1
  );
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  @media (max-width: 991px) {
    margin-top: 20px;
    justify-content:start;
  }
`;

const Div39 = styled.div`
  margin-top: 33%;
  width: 100%;
  max-width: 1410px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 100%;
  }
`;

const Div40 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 38%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div41 = styled.div`
  disply: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 499px;
  flex-grow: 1;
  align-items: end;
  padding: 50px 0 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding-left: 20px;
  }
`;

const Img8 = styled.img`
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 90%;
  }
`;

const Div43 = styled.div`
  display: flex;
  margin-top: 6px;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div44 = styled.div`
  align-self: start;
  display: flex;
  gap: 8px;
`;

const Div45 = styled.div`
  color: #ff7a00;
  text-align: right;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 24px Oleo Script, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;


const Div46 = styled.div`
  color: #000;
  align-self: stretch;
  margin-top: 35px;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div47 = styled.div`
  color: #ff7a00;
  text-align: justify;
  align-self: stretch;
  margin-top: 25px;
  font: 400 20px/32px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div47a = styled.div`
  color: #000;
  text-align: justify;
  align-self: stretch;
  margin-top: 25px;
  font: 400 20px/32px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div48 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 35px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div49 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`;


const Div50 = styled.div`
  color: #000;
  text-align: justify;
  align-self: start;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div51 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`;

const Div53 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 21px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div54 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`;

const Div56 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`;

const Div58 = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: 21px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div59 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`;


const Div61 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
`;

const Div68 = styled.div`
  color: #fff;
  cursor:pointer;
  border-radius: 5px;
  background-color: #2972c1;
  align-self: start;
  margin-top: 34px;
  justify-content: center;
  padding: 21px 45px;
  font: 500 18px Roboto, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 20px 20px;
  }
`;

const Div69 = styled.div`
  margin-top: 107px;
  width: 100%;
  max-width: 1408px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div70 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column9 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div71 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Div72 = styled.div`
  align-self: start;
  display: flex;
  gap: 8px;
`;

const Div73 = styled.div`
  color: #ff7a00;
  text-align: right;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 24px Oleo Script, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div74 = styled.div`
  color: #000;
  align-self: stretch;
  margin-top: 30px;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div76 = styled.div`
  align-self: stretch;
  margin-top: 29px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div77 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column10 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 88%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div78 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 28px;
  }
`;

const Div79 = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`;

const Div80 = styled.div`
  color: #000;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div81 = styled.div`
  display: flex;
  margin-top: 19px;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`;

const Div82 = styled.div`
  color: #000;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div83 = styled.div`
  display: flex;
  margin-top: 19px;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`;


const Div84 = styled.div`
  color: #000;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div85 = styled.div`
  display: flex;
  margin-top: 19px;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`;

const Div86 = styled.div`
  color: #000;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div87 = styled.div`
  display: flex;
  margin-top: 19px;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`;

const Div88 = styled.div`
  color: #000;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
const Column12 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 90%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img26 = styled.img`
  aspect-ratio: 1.02;
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-top: 42px;
  flex-grow: .3;
  @media (max-width: 991px) {
    aspect-ratio: 1;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div90 = styled.div`
  align-self: stretch;
  margin-top: 99px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div91 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column13 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img27 = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 36px;
  }
`;

const Column14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div92 = styled.div`
  border-radius: 10px;
  // background-image: url(${william});
  background-repeat:round;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 92%;
  padding: 50px 30px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 36px;
    padding: 0 20px;
  }
`;

const Div93 = styled.div`
  display: flex;
  width: 92%;
  max-width: 100%;
  flex-direction: column;
  margin: 82px 0 51px 52px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const Div94 = styled.div`
  color: #ff9533;
  text-align: right;
  align-self: center;
  white-space: nowrap;
  font: 500 24px Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div95 = styled.div`
  color: #000;
  text-align: center;
  align-self: center;
  margin-top: 22px;
  font: 700 48px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const Div96 = styled.div`
  color: #000;
  text-align: center;
  margin-top: 23px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div97 = styled.div`
  margin-top: 100px;
  width: 100%;
  max-width: 1410px;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div98 = styled.div`
  gap: 20px;
  display: flex;
  justify-content:center;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column15 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div99 = styled.div`
  border-radius: 12px;
  box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 16px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 49px 50px 49px 36px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;
const Div100 = styled.div`
  color: #2972c1;
  align-self: stretch;
  margin-top: 27px;
  font: 600 24px Manrope, sans-serif;
`;
const DivHead = styled.div`
  color: #2972c1;
  padding:30px;
  align-self: stretch;
  margin-top: 27px;
  font: 600 24px Manrope, sans-serif;
`;

const Div101 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  align-self: stretch;
  margin-top: 12px;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Column16 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 80%;
  }
`;

const Div102 = styled.div`
  border-radius: 12px;
  box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 16px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 49px 50px 49px 36px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div103 = styled.div`
  color: #2972c1;
  align-self: stretch;
  margin-top: 27px;
  font: 600 24px Manrope, sans-serif;
`;

const Div104 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  align-self: stretch;
  margin-top: 12px;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Column17 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div105 = styled.div`
  border-radius: 12px;
  box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 16px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 49px 50px 49px 36px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div106 = styled.div`
  color: #2972c1;
  align-self: stretch;
  margin-top: 27px;
  font: 600 24px Manrope, sans-serif;
`;

const Div107 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  align-self: stretch;
  margin-top: 12px;
  font: 400 16px/24px Roboto, sans-serif;
`;

const Column18 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 25%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div108 = styled.div`
  border-radius: 12px;
  box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 16px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 49px 50px 49px 36px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div109 = styled.div`
  color: #2972c1;
  align-self: stretch;
  margin-top: 27px;
  font: 600 24px Manrope, sans-serif;
`;

const Div110 = styled.div`
  color: rgba(43, 37, 64, 0.7);
  align-self: stretch;
  margin-top: 12px;
  font: 400 16px/24px Roboto, sans-serif;
`;




export default HomePage;
