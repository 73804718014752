import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";
import FEHHeader from "./FEHHeader";
import george4 from "./asset/img/george4.png";
import birds from "./asset/img/birds.png";
import book from "./asset/img/book.png";
import hand from "./asset/img/hand.png";
import pegion from "./asset/img/pegion.png";
import secret from "./asset/img/secret.png";
import { Link, Route, Routes, useParams } from 'react-router-dom'

function EditQuotes(props) {
  const { id } = useParams();
  const [loadmoreweklypost, setloadmoreweklypost] = useState(2)
  const [quotes, setquotes] = useState(null)
  const [loadmoreaudiencepost, setloadmoreaudiencepost] = useState(2)
  const [title, settitle] = useState(null)
  const [subtext, setsubtext] = useState(null)
  const [texts, settexts] = useState(null)

  // console.log(loadmoreweklypost, 'loadmoreweklypost')
  useEffect(() => {
    // fetchWeeklyPost
    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchWeeklyPost', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }).then(res => res.json())
      .then(res => {
        console.log(res.data.weeklyPost)
        setweeklypost(res.data.weeklyPost.posts)
        setquotes(res.data.weeklyPost.posts[id])
        console.log(res.data.weeklyPost.posts[id])
        settitle(res.data.weeklyPost.posts[id].title)
        setsubtext(res.data.weeklyPost.posts[id].subtext)
        // setBlogs(res.data.blogData)
        // setrefetch(!refetch)
      }).catch(e => {
        console.log(e)
        alert('Something went wrong,please try again')
      });
  }, []);
  useEffect(() => {
    // fetchWeeklyPost
    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/fetchAudiencePost', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    }).then(res => res.json())
      .then(res => {
        console.log(res.data)
        setaudiencePost(res.data.audiencePosts.audiencePost)
        // setBlogs(res.data.blogData)
        // setrefetch(!refetch)
      }).catch(e => {
        console.log(e)
        alert('Something went wrong,please try again')
      });
  }, []);
  const [weeklypost, setweeklypost] = useState([])
  let now = new Date().toDateString()

  const today = now
  // .toISOString()
  // .toISOString().slice(0, 10)
  let weeklyposttemp = [...weeklypost]
  let [audiancePost, setaudiencePost] = useState([])
  let audiancePostTemp = [...audiancePost]
  let audiancePostTempt = [...audiancePost]
  // console.log(audiancePostTemp, 'audiancePostTempaudiancePostTempaudiancePostTemp')
  return (
    <div style={{ width: '100%' }}>
      <Div>
        <FEHHeader />
        <Div15>
          <Div16>
            <Div17>Weekly post</Div17>
          </Div16>
        </Div15>

        <Div36>
          <Div37>
            <Div38>Weekly post Edit</Div38>
            <div style={{
              color: "rgba(255,122,0,1)",
              fontSize: '21px',
              fontWeight: '800',
              objectFit: 'contain',
              objectPosition: 'center',
              overflow: 'hidden',
              alignSelf: 'center',
            }}>. . .</div>
          </Div37>
          <Div39>{today}</Div39>
          <Div40 />
          <Div41>

            {weeklypost == [] && <div style={{ backgroundColor: '#000', padding: '10px', margin: '10px' }}>Loading</div>}
            {/* {weeklyposttemp && weeklyposttemp.splice(0, loadmoreweklypost).map(x => {
              return <Div46>
                <Div47>
                  <Column5>
                    <Img7
                      loading="lazy"
                      src={x?.image && x?.image != '' ? x.image : hand}
                    />
                  </Column5>
                  <Column6>
                    <Div48>
                      <Div49>{x.title}</Div49>
                      <Div50>{x.subtext} </Div50>
                    </Div48>
                  </Column6>
                </Div47>
              </Div46>
            }
            )} */}

            {quotes && <Div46>
              <Div47>
                <Column5>
                  <Img7
                    loading="lazy"
                    src={quotes?.image && quotes?.image != '' ? quotes.image : hand}
                  />
                </Column5>
                <Column6>
                  <Div48>
                    <Div49 value={title} onChange={(e) => { settitle(e.target.value) }} />
                    <Div50 value={subtext} onChange={(e) => setsubtext(e.target.value)} />
                  </Div48>
                </Column6>
              </Div47>
            </Div46>}
          </Div41>
          <Div56 onClick={() => {

            fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/editWeeklyPost', {
              method: 'POST',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ index: id, title: title, subtext: subtext })
            }).then(res => res.json())
              .then(res => {
                console.log(res)
                // setaudiencePost(res.data.audiencePosts.audiencePost)
                // setBlogs(res.data.blogData)
                if (res.code == 200) alert('Edit successfull')
                // setrefetch(!refetch)
              }).catch(e => {
                console.log(e)
                alert('Something went wrong,please try again')
              });

          }}>Submit</Div56>
          <Div57>
            <Div58>Audience Post</Div58>
            <div style={{
              color: "rgba(255,122,0,1)",
              fontSize: '21px',
              fontWeight: '800',
              objectFit: 'contain',
              objectPosition: 'center',
              overflow: 'hidden',
              alignSelf: 'center',
            }}>. . .</div>
          </Div57>
          <Div59>Share Your Voice with Me</Div59>
          <Div60 />
          <div style={{ display: 'flow' }}>
            {audiancePostTempt
              .splice(0, loadmoreaudiencepost)
              .map((item, index) => {
                // console.log(item, index, audiancePostTemp[index], audiancePost[index], 'this is item')

                if (index % 2 == !0) return
                else return <Div61 key={index}>

                  <Div62>
                    {audiancePostTemp[index] && <Column9>
                      <Div63>
                        <Div64>
                          <Div65>

                            <Div66>
                              <Div67>{audiancePostTemp[index].name}</Div67>
                              <Div68>@ {audiancePostTemp[index].username}</Div68>
                            </Div66>
                          </Div65>
                          <Div69>{audiancePostTemp[index].date}</Div69>
                        </Div64>
                        <Div70>
                          {audiancePostTemp[index].subtext}
                        </Div70>
                      </Div63>
                    </Column9>}
                    {audiancePostTemp[index + 1] && <Column10>
                      <Div71>
                        <Div72>
                          <Div73>

                            <Div74>
                              <Div75>{audiancePostTemp[index + 1].name}</Div75>
                              <Div76>{audiancePostTemp[index + 1].username}</Div76>
                            </Div74>
                          </Div73>
                          <Div77>{audiancePostTemp[index + 1].date}</Div77>
                        </Div72>
                        <Div78>
                          {audiancePostTemp[index + 1].subtext}
                        </Div78>
                      </Div71>
                    </Column10>}
                  </Div62>
                </Div61>
              }
              )}
            {/* {audiancePostTemp
              .splice(0, loadmoreaudiencepost)
              .map((item, index) => { return <div>{item.name}</div> })
            } */}

          </div>

          <Div56 onClick={() => {
            // alert('will load more')
            let length = audiancePost.length
            // console.log(audiancePost, 'audiancePostaudiancePost')
            if (length > loadmoreaudiencepost) {

              let max = loadmoreaudiencepost + 2
              if (max > length) {

                setloadmoreaudiencepost(length)
              }
              else {
                setloadmoreaudiencepost(max)
              }
              // return max
              // console.log(max, audiancePost, 'audiancePostaudiancePostmax')
            }
            // })
            else {
              setloadmoreaudiencepost(length)
              alert('Reached max available Audience posts')
            }

          }}>Load More</Div56>
        </Div36>

        <Footer />
      </Div>
    </div>
  );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;


const Div15 = styled.div`
  background-color: #183862;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  margin-top:40px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div16 = styled.div`
  display: flex;
  width: 130px;
  max-width: 100%;
  flex-direction: column;
  margin: 42px 0 63px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const Div17 = styled.div`
  color: #fff;
  text-align: center;
  letter-spacing: 0.72px;
  white-space: nowrap;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div18 = styled.div`
  display: flex;
  margin-top: 25px;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
`;

const Div19 = styled.div`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div20 = styled.div`
  border-radius: 50%;
  display: flex;
  width: 6px;
  height: 6px;
  flex-direction: column;
`;

const Div21 = styled.div`
  color: #fff;
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div22 = styled.div`
  background-color: #f4f4f4;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Div23 = styled.div`
  width: 100%;
  max-width: 1650px;
  margin: 50px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 0;
  }
`;

const Div24 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 35%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div25 = styled.div`
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 680px;
  justify-content: center;
  align-items: end;
  margin: auto 0;
  padding: 48px 0 48px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding-left: 20px;
  }
`;

const Img4 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div26 = styled.div`
  position: relative;
  color: #fff;
  white-space: nowrap;
  background-color: #2972c1;
  margin-bottom: 14px;
  justify-content: center;
  padding: 16px 15px;
  font: 400 20px Berkshire Swash, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    margin-bottom: 40px;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 95%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div27 = styled.div`
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  padding-bottom: 50px;
  flex-direction: column;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div28 = styled.div`
  background-color: #2972c1;
  display: flex;
  height: 12px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div29 = styled.div`
  display: flex;
  margin-top: 58px;
  flex-direction: column;
  padding: 0 58px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div30 = styled.div`
  color: #13335c;
  text-align: center;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div31 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 24px;
  font: 400 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div32 = styled.div`
  color: #13335c;
  text-align: center;
  margin-top: 59px;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div33 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 24px;
  font: 400 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div34 = styled.div`
  color: #13335c;
  margin-top: 41px;
  font: 400 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div35 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 41px;
  font: 400 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div36 = styled.div`
  align-self: center;
  display: flex;
  margin-top: 106px;
  max-width: 100%;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div37 = styled.div`
  align-self: start;
  display: flex;
  gap: 8px;
`;

const Div38 = styled.div`
  color: #ff7a00;
  text-align: right;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 24px Oleo Script, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img5 = styled.img`
  aspect-ratio: 4.5;
  object-fit: contain;
  object-position: center;
  width: 36px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div39 = styled.div`
  color: var(--Title-color, #1f2127);
  align-self: stretch;
  margin-top: 32px;
  font: 500 36px/133% Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div40 = styled.div`
  background-color: #ff7a00;
  align-self: stretch;
  margin-top: 27px;
  height: 3px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div41 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  align-self: stretch;
  margin-top: 48px;
  padding-right: 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
    margin-top: 40px;
  }
`;

const Div42 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;


const Img6 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 330px;
  fill: url(<path-to-image>), lightgray 50% / cover no-repeat;
  overflow: hidden;
  max-width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Column4 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 74%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div43 = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div44 = styled.div`
  color: #13335c;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div45 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 24px;
  font: 400 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div46 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  align-self: stretch;
  margin-top: 32px;
  padding-right: 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const Div47 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column5 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 26%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img7 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 330px;
  fill: url(<path-to-image>), lightgray -25.308px 0px / 145.49% 100% no-repeat;
  overflow: hidden;
  max-width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Column6 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 74%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div48 = styled.div`
  display: flex;
  margin-top: 47px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div49 = styled.input`
  color: #13335c;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div50 = styled.textarea`
  color: #13335c;
  height:50vh;
  width:60vw;
  text-align: justify;
  margin-top: 24px;
  font: 400 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div51 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  align-self: stretch;
  margin-top: 32px;
  padding-right: 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const Div52 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 26%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Img8 = styled.img`
  aspect-ratio: 1.03;
  object-fit: contain;
  object-position: center;
  width: 330px;
  fill: url(<path-to-image>), lightgray 0px -168.458px / 100% 155.009% no-repeat;
  overflow: hidden;
  max-width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
`;

const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 74%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div53 = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div54 = styled.div`
  color: #13335c;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div55 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 24px;
  font: 400 24px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div56 = styled.div`
  color: #383838;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  background-color: #f7f7f7;
  align-self: stretch;
  cursor:pointer;
  margin-top: 48px;
  justify-content: center;
  text-align:center;
  align-items: center;
  padding: 34px 60px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
   user-select: none;
  font: 500 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    margin-top: 40px;
    padding: 20 20px;
  }
`;

const Div57 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 106px;
  gap: 8px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Div58 = styled.div`
  color: #ff7a00;
  text-align: right;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 24px Oleo Script, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img9 = styled.img`
  aspect-ratio: 4.5;
  object-fit: contain;
  object-position: center;
  width: 36px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
`;

const Div59 = styled.div`
  color: var(--Title-color, #1f2127);
  align-self: stretch;
  margin-top: 37px;
  font: 500 36px/133% Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div60 = styled.div`
  background-color: #ff7a00;
  align-self: stretch;
  margin-top: 27px;
  height: 3px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div61 = styled.div`
  align-self: stretch;
  margin-top: 48px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div62 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column9 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div63 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 30px 24px;
  @media (max-width: 991px) {
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div64 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div65 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Img10 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100%;
`;

const Div66 = styled.div`
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
`;

const Div67 = styled.div`
  color: #1c599c;
  white-space: nowrap;
  font: 600 16px/140% Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div68 = styled.div`
  color: #787878;
  margin-top: 11px;
  font: 500 12px/140% Manrope, sans-serif;
`;

const Div69 = styled.div`
  color: #787878;
  text-align: right;
  align-self: start;
  margin-top: 6px;
  font: 600 16px/140% Barlow, sans-serif;
`;

const Div70 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 22px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column10 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div71 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 30px 24px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div72 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div73 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Img11 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100%;
`;

const Div74 = styled.div`
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
`;

const Div75 = styled.div`
  color: #1c599c;
  white-space: nowrap;
  font: 600 16px/140% Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div76 = styled.div`
  color: #787878;
  margin-top: 8px;
  white-space: nowrap;
  font: 500 12px/140% Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div77 = styled.div`
  color: #787878;
  text-align: right;
  align-self: start;
  margin-top: 6px;
  font: 600 16px/140% Barlow, sans-serif;
`;

const Div78 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 22px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div79 = styled.div`
  align-self: stretch;
  margin-top: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div80 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column11 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div81 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 24px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div82 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div83 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Img12 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100%;
`;

const Div84 = styled.div`
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
`;

const Div85 = styled.div`
  color: #1c599c;
  white-space: nowrap;
  font: 600 16px/140% Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div86 = styled.div`
  color: #787878;
  margin-top: 11px;
  font: 500 12px/140% Manrope, sans-serif;
`;

const Div87 = styled.div`
  color: #787878;
  text-align: right;
  align-self: start;
  margin-top: 6px;
  font: 600 16px/140% Barlow, sans-serif;
`;

const Div88 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 22px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column12 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div89 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 30px 24px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div90 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div91 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Img13 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100%;
`;

const Div92 = styled.div`
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
`;

const Div93 = styled.div`
  color: #1c599c;
  white-space: nowrap;
  font: 600 16px/140% Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div94 = styled.div`
  color: #787878;
  margin-top: 11px;
  white-space: nowrap;
  font: 500 12px/140% Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div95 = styled.div`
  color: #787878;
  text-align: right;
  align-self: start;
  margin-top: 6px;
  font: 600 16px/140% Barlow, sans-serif;
`;

const Div96 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 22px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div97 = styled.div`
  align-self: stretch;
  margin-top: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div98 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column13 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div99 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 30px 24px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div100 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div101 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Img14 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100%;
`;

const Div102 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 6px;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

const Div103 = styled.div`
  color: #1c599c;
  white-space: nowrap;
  font: 600 16px/140% Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div104 = styled.div`
  color: #787878;
  margin-top: 11px;
  white-space: nowrap;
  font: 500 12px/140% Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div105 = styled.div`
  color: #787878;
  text-align: right;
  align-self: start;
  margin-top: 6px;
  font: 600 16px/140% Barlow, sans-serif;
`;

const Div106 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 22px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div107 = styled.div`
  border-radius: 5px;
  background-color: #f7f7f7;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 30px 24px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const Div108 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Div109 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const Img15 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 41px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100%;
`;

const Div110 = styled.div`
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
`;

const Div111 = styled.div`
  color: #1c599c;
  white-space: nowrap;
  font: 600 16px/140% Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div112 = styled.div`
  color: #787878;
  margin-top: 11px;
  font: 500 12px/140% Manrope, sans-serif;
`;

const Div113 = styled.div`
  color: #787878;
  text-align: right;
  align-self: start;
  margin-top: 6px;
  font: 600 16px/140% Barlow, sans-serif;
`;

const Div114 = styled.div`
  color: #13335c;
  text-align: justify;
  margin-top: 22px;
  font: 400 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div115 = styled.div`
  color: #383838;
  white-space: nowrap;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  background-color: #f7f7f7;
  align-self: stretch;
  margin-top: 48px;
  justify-content: center;
  align-items: center;
  padding: 34px 60px;
  font: 500 18px Manrope, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div116 = styled.div`
  align-self: stretch;
  margin: 100px 31px 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 10px 0 0;
  }
`;

const Div117 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column15 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div118 = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 388px;
  flex-grow: 1;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Img16 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const Div119 = styled.div`
  position: relative;
  color: #d8d8d8;
  max-width: 100%;
  border-radius: 5px;
  background: linear-gradient(
    270deg,
    rgba(12, 37, 68, 0.23) 31.77%,
    #0c2544 100%
  );
  justify-content: center;
  align-items: start;
  padding: 96px 60px 115px 47px;
  font: 500 30px/40px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const Column16 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div120 = styled.div`
  display: flex;
  flex-direction: column;
  color: #d8d8d8;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  min-height: 388px;
  flex-grow: 1;
  justify-content: center;
  align-items: start;
  padding: 96px 60px 147px;
  font: 500 30px/40px Barlow, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 40px 20px 40px 24px;
  }
`;

const Img17 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;



export default EditQuotes;
