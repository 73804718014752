import React, { useEffect, useState } from "react";
import styled from "styled-components";
import george4 from './asset/img/george4.png'
import blog from './asset/img/blog.png'
import { FaFontAwesome } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import Footer from "./Footer";
import FEHHeader from "./FEHHeader";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';


function Blog(props) {
  // var extractedText = text.split(' ').slice(0, 100).join(' '),

  const history = useNavigate();
  const [blogs, setBlogs] = useState(null)
  useEffect(() => {
    console.log('blogs access')
    fetch('https://australia-southeast1-medvatic-438af.cloudfunctions.net/georgePersonal/blogs').then(async res => {
      let k = await res.json()
      console.log('this is res', k)
      setBlogs(k.data.blogs)
    }).catch(error => {
      console.log(error)
    })
  }, []);

  return (
    <Div>
      <FEHHeader />
      <Div15>
        <Div16>
          <Div17>Blog</Div17>
        </Div16>
      </Div15>
      <Div22>
        <Div23>
          {!blogs && <div>loading</div>}
          {blogs && blogs.map(x => <Column>
            <Div24>
              <Img4
                loading="lazy"
                srcSet={blog}
              />
              <Div25>
                {x.heading}
              </Div25>
              <Div26>
                {x?.text ? x?.text?.split(' ').slice(0, 50).join(' ') : '.'}...
              </Div26>
              <Div34 onClick={() => {
                history(`/BlogDetails/${x.blogId}`)
              }}>
                <Div35 >Read More</Div35>
                <FaArrowRight style={{ width: '30px', height: '15px', color: '#2972c1' }} />
              </Div34>
            </Div24>
          </Column>)}



        </Div23>
      </Div22>
      <Footer />
    </Div>
  );
}

const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Div15 = styled.div`
  background-color: #183862;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div16 = styled.div`
  display: flex;
  width: 104px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 42px 0 59px;
  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const Div17 = styled.div`
  color: #fff;
  text-align: center;
  letter-spacing: 0.72px;
  white-space: nowrap;
  font: 500 36px Barlow, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
const Div22 = styled.div`
  margin-top: 100px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Div23 = styled.div`
  gap: 5px;
  display: flex;
  flex-wrap:wrap;
  justify-content:center;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 33%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div24 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Img4 = styled.img`
  aspect-ratio: 1.28;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div25 = styled.div`
  color: #0a0d14;
  margin-top: 23px;
  align-self:center;
  font: 500 22px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div26 = styled.div`
  color: #696969;
  margin-top: 19px;
  align-self:center;
  font: 400 16px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div34 = styled.div`
  border: 1px solid #2972c1;
  margin-top: 29px;
  cursor:pointer;
  display: flex;
  align-items: center;
  width:50%;
  justify-content: center;
  gap: 15px;
  align-self:center;
  padding: 15px 27px;
  @media (max-width: 991px) {
    padding: 10 20px;
  }
`;

const Div35 = styled.div`
  color: #2972c1;
  margin: auto 0;
  font: 500 18px Manrope, sans-serif;
`;

export default Blog;
