import React from 'react';
import { useNavigate } from 'react-router-dom';
// import {}
import { Link, Route, Routes, } from 'react-router-dom'

import About from './About';
import HomePage from './HomePage';
import Blog from './Blog';
import BlogDetails from './BlogDetails';
import Contact from './Contact';
import Quotes from './Quotes';
import Test from './Test';
import EditBlogDetails from './EditBlogDetails';
import Login from './Login';
import ShowMenu from './ShowMenu';
import EditQuotes from './EditQuotes';
import EditAudiencePostDetails from './EditAudiencePostDetails';
import EditHomePage from './EditHomePage';
import EditAboutPage from './EditAboutPage';
export default function App() {
  // const navigate = useNavigate();
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/About" element={<About />} />
      <Route exact path="/Blog" element={<Blog />} />
      <Route exact path="/Quotes" element={<Quotes />} />
      <Route exact path="/home" element={<HomePage />} />
      <Route exact path="/Contact" element={<Contact />} />
      <Route exact path="/Test" element={<Test />} />
      <Route exact path="/Login" element={<Login />} />
      <Route exact path="/ShowMenu" element={<ShowMenu />} />
      <Route exact path="/BlogDetails/:id" element={<BlogDetails />} />
      <Route exact path="/EditBlogDetails/:id" element={<EditBlogDetails />} />
      <Route exact path="/EditQuotes/:id" element={<EditQuotes />} />
      <Route exact path="/EditAudiencePostDetails/:id" element={<EditAudiencePostDetails />} />
      <Route exact path="/EditHomePage" element={<EditHomePage />} />
      <Route exact path="/EditAboutPage" element={<EditAboutPage />} />
      <Route path='/*' element={<HomePage />} />
    </Routes>
  );
}
