import React from 'react';
import styled from "styled-components";
import logo from './asset/img/logo.png'


const Footer = () => {
  return (<Div111>
    <Div112>
      <Div113>
        <Column19>
          <Div114>
            <Img32
              loading="lazy"
              srcSet={logo}
            />
            <Div115>
              “Empower Peace, Ignite Creativity”
            </Div115>
          </Div114>
        </Column19>
      </Div113>
    </Div112>
    <Div132>© 2023 George Maclean. All rights reserved</Div132>
  </Div111>
  );
}


const Div111 = styled.div`
  background-color: #f1f1f1;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 43px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div112 = styled.div`
  margin-bottom: 19px;
  width: 100%;
  max-width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div113 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const Column19 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  line-height: normal;
  width: 100%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Div114 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Img32 = styled.img`
  aspect-ratio: 1.02;
  object-fit: contain;
  object-position: center;
  width: 133px;
  overflow: hidden;
  max-width: 100%;
`;

const Div115 = styled.div`
  color: #000;
  margin-top: 40px;
  font: 400 20px Manrope, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div132 = styled.div`
  color: #fff;
  background-color: #14365f;
  align-self: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 25px 60px;
  font: 400 20px Roboto, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

export default Footer;
